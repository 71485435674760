<template>
    <TabColumnsDesktop :blok="blok" :features="features" :sections="sections" />
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import TabColumnsDesktop from '~/components/Layout/Navigation/Tabs/Desktop/TabColumnsDesktop.vue'

const props = defineProps({
    blok: {
        type: Object as PropType<Record<string, any>>,
        default: () => {},
    },
})

const features = computed(() =>
    props.blok.menu.filter((item: any) => item.component === 'nav-feature'),
)

const sections = computed(() =>
    props.blok.menu.filter((item: any) => item.component === 'nav-group'),
)
</script>
